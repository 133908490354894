import { memo } from "react";
import styled from "styled-components";
import { ICONS } from "../../icons";


/* eslint-disable-next-line */
export interface AppleStoreProps {}

const StyledAppleStore = styled.a`
	outline: none;
	text-decoration: none;
	cursor: pointer;
`;

const StyledAppleStoreImg = styled.img`
	width: 16rem;
	height: 4.8rem;
`;

export function AppleStore(props: AppleStoreProps) {
	return (
		<StyledAppleStore>
			<StyledAppleStoreImg alt="Apple Store" src={ICONS.AppleStoreSVG} />
		</StyledAppleStore>
	);
}

export default memo(AppleStore);
