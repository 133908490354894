import { RecoverySteps, RECOVERY_FLOW } from "./recovery.constants";
import { RecoveryState, RECOVERY_FEATURE_KEY } from "./recovery.slice";
import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../index";

const getRecoveryState = (rootState: StoreState): RecoveryState => rootState[RECOVERY_FEATURE_KEY];

const getRecoveryStep = createSelector(getRecoveryState, (state: RecoveryState) => {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const flow: RecoverySteps[] = RECOVERY_FLOW[state.flow!];
	return flow[state.currentStepIndex];
});

const getEmail = createSelector(getRecoveryState, (state: RecoveryState) => state.email);

const getToken = createSelector(getRecoveryState, (state: RecoveryState) => state.token);

const getEmailStatus = createSelector(getRecoveryState, (state: RecoveryState) => state.emailStatus);

const getResendEmailStatus = createSelector(getRecoveryState, (state: RecoveryState) => state.resendEmailStatus);


export const recoverySelectors = {
	getRecoveryState,
	getRecoveryStep,
	getEmail,
	getToken,
	getEmailStatus,
	getResendEmailStatus
};
