import React, { forwardRef, memo, useEffect, useId, useRef, useState } from "react";
import Stack from "../Stack/Stack";
import { ICONS } from "../../icons";
import { StyledBottomRow, StyledEyeIcon, StyledInput, StyledInputWrapper, StyledLabel, StyledLabelRow } from "./TextInput.elements";
import { Text } from "../index";

export enum TextInputType {
	TEXT = "INPUT_TYPE/TEXT",
	PASSWORD = "INPUT_TYPE/PASSWORD",
	EMAIL = "INPUT_TYPE/EMAIL",
}

export interface TextInputProps {
	label?: string;
	type?: TextInputType;
	onChange?: (value: string) => void;
	value?: string;
	placeholder?: string;
	disabled?: boolean;
	error?: string;
	maxCharacterNumber?: number;
	optional?: boolean;
	bottomMessage?: string;
	// react-hook-form
	name?: string;
	register?: any;
	ghost?: boolean;
}

export const TextInput = forwardRef((props: TextInputProps, ref) => {
	const {
		label,
		type = TextInputType.TEXT,
		onChange,
		value,
		name,
		register,
		placeholder = "",
		disabled = false,
		error,
		maxCharacterNumber = undefined,
		optional = false,
		bottomMessage = "",
		ghost = false,
	} = props;

	const id = useId();
	const { onChange: inputChange, onBlur, name: inputName, ref: inputRef } = register(name);

	const [showInput, setShowInput] = useState(type !== TextInputType.PASSWORD);
	const [textValue, setTextValue] = useState(value);

	const charactersUsed = textValue?.length ?? 0;
	const inputType = showInput ? type : "password";

	const handleOnChangeEvent = (e: React.ChangeEvent<HTMLInputElement>): void => {
		inputChange(e);
		const changeTextValue = e?.target?.value ?? "";
		if (onChange) onChange(changeTextValue);
		setTextValue(changeTextValue);
	};

	const renderBottomMessageRow = () => {
		const hasError = !!error;
		const showBottomMessageRow = hasError || !!bottomMessage || maxCharacterNumber !== undefined;
		if (!showBottomMessageRow) return;

		const isOnlyNumbers = maxCharacterNumber && !hasError && !bottomMessage;
		return (
			<StyledBottomRow>
				{isOnlyNumbers && <span />}
				{bottomMessage && <Text.Paragraph.xp>{bottomMessage}</Text.Paragraph.xp>}
				{error && <Text.Paragraph.xp color={"danger300"}>{error}</Text.Paragraph.xp>}
				{!!maxCharacterNumber && (
					<Text.Paragraph.xp color={hasError ? "danger300" : undefined}>{`${charactersUsed}/${maxCharacterNumber}`}</Text.Paragraph.xp>
				)}
			</StyledBottomRow>
		);
	};

	const newRef = useRef(null) as React.MutableRefObject<HTMLInputElement | null>;

	const handleInputWrapperClick = () => {
		newRef?.current?.focus();
	};

	useEffect(() => {
		newRef.current = document.getElementById(id) as HTMLInputElement;
	}, []);

	const handleEyeClick = (e: any) => {
		e.stopPropagation();
		setShowInput((prevState) => !prevState);
	};

	const showEyeIcon = type === TextInputType.PASSWORD;
	const hasError = !!error;

	const [isFocused, setIsFocused] = useState<boolean>(false);

	return (
		<Stack vertical spacing={1} block>
			<StyledLabelRow>
				<StyledLabel htmlFor={inputName}>
					<Text.Paragraph.sp bold>{label}</Text.Paragraph.sp>
				</StyledLabel>
				{optional && <Text.Paragraph.xp color="netural600">(Optional)</Text.Paragraph.xp>}
			</StyledLabelRow>
			<StyledInputWrapper isFocused={isFocused} hasError={hasError} onClick={handleInputWrapperClick}>
				<StyledInput
					id={id}
					placeholder={placeholder}
					disabled={disabled}
					type={inputType}
					onChange={handleOnChangeEvent}
					ref={inputRef}
					onBlur={(e) => {
						onBlur(e);
						setIsFocused(false);
					}}
					name={inputName}
					onFocus={() => setIsFocused(true)}
					ghost={ghost}
				/>
				{showEyeIcon && <StyledEyeIcon src={ICONS.Eye} alt="Password toggle icon" onClick={handleEyeClick} />}
			</StyledInputWrapper>
			{renderBottomMessageRow()}
		</Stack>
	);
});

export default memo(TextInput);
