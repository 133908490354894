import { StyledStack } from "./Stack.elements";
import { memo, ReactNode } from "react";
import { CSSProperties } from "styled-components";

/* eslint-disable-next-line */
export interface StackProps {
	spacing?: number;
	vertical?: boolean;
	block?: boolean;
	center?: boolean;
	children?: ReactNode;
	spaceBetween?: boolean;
	style?: CSSProperties | undefined;
	stretch?: boolean;
	end?: boolean;
}

export function Stack({
	stretch = false,
	spaceBetween = false,
	block = false,
	center = false,
	spacing = 0,
	vertical = false,
	end = false,
	children,
	style,
}: StackProps) {
	return (
		<StyledStack
			stretch={stretch}
			spacing={spacing}
			vertical={vertical}
			block={block}
			center={center}
			end={end}
			spaceBetween={spaceBetween}
			style={style}
		>
			{children}
		</StyledStack>
	);
}

export default memo(Stack);
