import { css } from "styled-components";
const animations = {};
const breakpoints = {
	xxl: 1320,
	xl: 1140,
	l: 960,
	s: 720,
	xs: 540,
};
const colors = {
	// Primary
	primary50: "#F4F5FF",
	primary100: "#DADAFF",
	primary200: "#766BEA",
	primary300: "#5D3FDE",
	primary400: "#493CA3",
	primary500: "#3C357D",
	primary600: "#312E5F",

	// Secondary
	secondary100: "",
	secondary200: "#C0F3EE",
	secondary300: "#23D0C5",
	secondary400: "",
	secondary500: "",

	// Tertiary
	tertiary100: "#FFD1D7",
	tertiary200: "",
	tertiary300: "",
	tertiary400: "",
	tertiary500: "",

	// Netural
	netural100: "#F9F9FA",
	netural200: "#E9EAEB",
	netural300: "#BEC1C3",
	netural400: "#7A7F86",
	netural500: "#5D646B",
	netural600: "#49515A",
	netural700: "#2E3741",
	netural800: "#18222D",
	netural900: "",

	// Success
	success100: "#D1FFDF",
	success200: "",
	success300: "#3BD07C",
	success400: "",
	success500: "#318256",

	// Warning
	warning100: "#FFDED3",
	warning200: "",
	warning300: "",
	warning400: "",
	warning500: "#B74A25",

	// Danger
	danger100: "#FFE3E2",
	danger200: "#F88178",
	danger300: "#F83B3E",
	danger400: "#D6252A",
	danger500: "#B12221",

	// Overlay
	overlay010: "rgba(13, 23, 34, 0.1)",
	overlay030: "rgba(13, 23, 34, 0.3)",

	// Crypto

	// System
	black: "",
	white: "#fff",
	support: "",
};
const fonts = {};
const fontSizes = {};
const spacing = {
	basic: 0.8, // rem
};

const radius = {
	basic: 0.8, //rem
};

const mixins = {
	shadows: {
		primaryCTA: css`
			box-shadow: 0px 10px 20px -10px rgba(91, 121, 217, 0.3), 0px 5px 30px -5px rgba(91, 121, 217, 0.2);
		`,
		elevation1: css`
			box-shadow: 0px 1px 5px rgba(24, 30, 45, 0.05);
		`,
		elevation2: css`
			box-shadow: 0px 2px 15px rgba(24, 30, 45, 0.05);
		`,
		elevation3: css`
			box-shadow: 0px 0px 10px rgba(64, 56, 95, 0.05), 0px 0px 40px rgba(81, 94, 108, 0.05);
		`,
		elevation4: css`
			box-shadow: 0px 4px 10px rgba(24, 30, 45, 0.05), 0px 5px 30px rgba(24, 30, 45, 0.08);
		`,
		elevation5: css`
			box-shadow: 0px 10px 15px rgba(24, 30, 45, 0.05), 0px 20px 40px rgba(56, 56, 56, 0.05);
		`,
		elevation6: css`
			box-shadow: 0px 15px 20px rgba(24, 30, 45, 0.05), 0px 20px 40px rgba(24, 30, 45, 0.05);
		`,
	},
};

const theme = {
	animations,
	breakpoints,
	colors,
	fonts,
	fontSizes,
	spacing,
	radius,
	mixins,
};

export type DefaultThemeProps = typeof theme;
export default theme;

export type ColorType = keyof typeof colors;
