import { recoverySlice } from "./recovery.slice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const reducerActions = recoverySlice.actions;

export enum RecoveryAction {
	SEND_RECOVERY_REQUEST = "recovery/sendRecoveryRequest",
	RESEND_RECOVERY_REQUEST = "recovery/resendRecoveryRequest",
	GET_QR = "recovery/getQR",
	GET_QR_RESPONSE = "recovery/getQRResponse",
	SEND_PASSWORD = "recovery/sendPassword",
}

let config = {
	headers: {
	  "Content-Type": "application/json",
	  'Access-Control-Allow-Origin': '*',
	  "Access-Control-Allow-Headers": "*",
	  "Access-Control-Allow-Credentials": true,
	}
}	

export const sendRecoveryRequest = createAsyncThunk(
	RecoveryAction.SEND_RECOVERY_REQUEST, 
	async (email: string, thunkAPI) => {
		const response = await axios.post("/api/users/recovery-request", {email: email}, config).then(res => res.data);
		if(response.result.reviewerType){
			thunkAPI.dispatch(recoveryActions.setReviewerType(response.result.reviewerType))
		}
	}
);

export const resendRecoveryRequest = createAsyncThunk(
	RecoveryAction.RESEND_RECOVERY_REQUEST, 
	async (email: string, thunkAPI) => {
		const response = await axios.post("/api/users/recovery-request", {email: email}, config).then(res => res.data);
		if(response.result.reviewerType){
			thunkAPI.dispatch(recoveryActions.setReviewerType(response.result.reviewerType))
		}
	}
);
const getQR = createAsyncThunk(RecoveryAction.GET_QR,
	async (token: string, thunkAPI) => {
		const response = await axios.get("/api/users/recovery?token=" + token, config).then(res => res.data);
		thunkAPI.dispatch(recoveryActions.setRecoveryData(response))
	}
)

const getQRResponse = createAsyncThunk(RecoveryAction.GET_QR_RESPONSE,
	async (operationUid: string, thunkAPI) => {
		const response = await axios.get(`/api/operations/${operationUid}`, config).then(res => res.data);
		thunkAPI.dispatch(recoveryActions.setQrStatus(response.state))
	}
)

const sendGooglePassword = createAsyncThunk(RecoveryAction.SEND_PASSWORD,
	async (password: string, thunkAPI) => {
		const response = await axios.post("/api/users/recovery/google", { password: password }, config).then(res => res.data);
		thunkAPI.dispatch(recoveryActions.setToken(response.recoveryRequestToken))
	}
)

const sendApplePassword = createAsyncThunk(RecoveryAction.SEND_PASSWORD,
	async (password: string, thunkAPI) => {
		const response = await axios.post("/api/users/recovery/apple", { password: password }, config).then(res => res.data);
		thunkAPI.dispatch(recoveryActions.setToken(response.recoveryRequestToken))
	}
)

export const recoveryActions = {
	sendRecoveryRequest,
	resendRecoveryRequest,
	getQR,
	getQRResponse,
	sendApplePassword,
	sendGooglePassword,
	...reducerActions,
};
