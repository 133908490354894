import styled, { keyframes } from "styled-components";

export const StyledWrapper = styled.div`
    width: 40px;
    height: 40px;
`

export const StyledRing = styled.div`
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
`


export const rotate = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`   
export const StyledDiv = styled.div`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 4px solid #218380;
    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #218380 transparent transparent transparent;

    &:nth-child(1) {
    animation-delay: -0.45s;
    }
    &:nth-child(2) {
    animation-delay: -0.3s;
    }
    &:nth-child(3) {
    animation-delay: -0.15s;
    }
`


  