import React, { memo, useEffect } from 'react';
import { withTranslation, WithTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import styled from "styled-components";
import { AppLanguage, uiActions } from './redux';
import RecoveryScreen from "./screens/RecoveryScreen"
import Cookies from 'universal-cookie';
import OpenBankingScreen from "./screens/OpenBankingScreen";

const StyledApp = styled.div`
	width: 100%;
	height: 100%;
`;


export function App({ t }: WithTranslation) {
	const cookies = new Cookies();
	const dispatch = useDispatch();

	useEffect(() => {
		const language = cookies.get("i18next");
		switch (language) {
			case "en":
				dispatch(uiActions.setApplicationLanguage(AppLanguage.EN));
				break;
			case "hu":
				dispatch(uiActions.setApplicationLanguage(AppLanguage.HU));
				break;
			case "lt":
				dispatch(uiActions.setApplicationLanguage(AppLanguage.LT));
				break;
			default:
				dispatch(uiActions.setApplicationLanguage(AppLanguage.EN));
		}
	},[])

	return (
		<StyledApp>
			<Route path="/recovery" exact render={() => <RecoveryScreen />} />
			<Route path="/openBanking" exact render={() => <OpenBankingScreen />} />
		</StyledApp>
	);
}

export default withTranslation()(memo(App));

