import { recoveryActions, recoverySelectors } from "../../redux";
import { Card, Stack, Text, TextInput, TextInputType, ButtonPrimary, Spinner } from "../index";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { memo, useEffect, useId, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyledRecoveryReviewPasswordStep } from "./RecoveryReviewPasswordStep.elements";
import { useDispatch, useSelector } from "react-redux";
import { StyledEmailResentError } from "../RecoveryFlowEmailSentStep/RecoveryFlowEmailSentStep.elements";
import { ICONS } from "../../icons";

export interface RecoveryReviewPasswordStepProps {}

interface FormValues {
	password: string;
}

export function RecoveryReviewPasswordStep(props: RecoveryReviewPasswordStepProps) {
	const [status, setStatus] = useState("");
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const formId = useId();

	const schema = Joi.object({
		password: Joi.string(),
	});

	const recoveryState = useSelector(recoverySelectors.getRecoveryState);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>({
		resolver: joiResolver(schema),
	});

	const onSubmit = (data: FormValues) => {
		if(recoveryState.reviewerType === "GOOGLE"){
			dispatch(recoveryActions.sendGooglePassword(data.password));
		}
		else {
			dispatch(recoveryActions.sendApplePassword(data.password));
		}
	};

	useEffect(() => {
		setStatus(recoveryState.sendPasswordStatus);
		if(recoveryState.sendPasswordStatus === "error"){
			setTimeout(() => {
				setStatus("");
			}, 3000)
		}
		if(recoveryState.sendPasswordStatus === "success"){
			dispatch(recoveryActions.startEmailFlow(recoveryState.token));
		}
	}, [recoveryState.sendPasswordStatus]);

	return (
		<StyledRecoveryReviewPasswordStep>
			<Card elevation={3} padding={{ x: 10, y: 5 }}>
				<Stack vertical center spacing={5}>
					<Text.Headline.h4 center bold color="primary600">
						{t("recovery.title")}
					</Text.Headline.h4>
					<Stack vertical center spacing={5}>
						<Text.Paragraph.p center style={{ maxWidth: "43rem" }}>
							{t("recovery.review.description")}
						</Text.Paragraph.p>
						<Stack block vertical spacing={10}>
							<form id={formId} onSubmit={handleSubmit(onSubmit)} style={{ width: "36rem", margin: "auto" }}>
								<TextInput
									error={errors.password?.message}
									register={register}
									label={t("recovery.review.password")}
									type={TextInputType.PASSWORD}
									placeholder={t("recovery.review.password")}
									name="password"
								></TextInput>
							</form>
							<Stack block vertical center spacing={3}>
								{(status !== "pending" && status !== "error") && (
									<ButtonPrimary isSubmit form={formId} style={{ minWidth: "16rem" }}>
										{t("common.button.next")}
									</ButtonPrimary>
								)}
								{status === "error" && (
									<StyledEmailResentError>
										<Stack center spacing={1}>
											<img src={ICONS.Cancel} alt="Error"></img>
											<Text.Paragraph.sp>{t("recovery.review.error")}</Text.Paragraph.sp>
										</Stack>
									</StyledEmailResentError>
								)}
								{status === "pending" && <Spinner/>}
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</Card>
		</StyledRecoveryReviewPasswordStep>
	);
}

export default memo(RecoveryReviewPasswordStep);
