import { Card, Stack, Text } from "../index";
import { memo } from "react";
import { StyledOpenBankingFailedStep } from "./OpenBankingFailedStep.elements";
import { useTranslation } from "react-i18next";

export interface OpenBankingFailedStepProps {}

export function OpenBankingFailedStep(props: OpenBankingFailedStepProps) {
	const { t } = useTranslation();

	return (
		<StyledOpenBankingFailedStep>
			<Card elevation={3} padding={{ x: 10, y: 10 }}>
				<Stack vertical center spacing={3}>
					<Text.Headline.h4 bold color="primary600">
						{t("openBanking.failedScreen.title")}
					</Text.Headline.h4>
					<Stack vertical center spacing={8}>
						<Text.Paragraph.p center>{t("openBanking.failedScreen.description")}</Text.Paragraph.p>
					</Stack>
				</Stack>
			</Card>
		</StyledOpenBankingFailedStep>
	);
}

export default memo(OpenBankingFailedStep);
