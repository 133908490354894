import { Card, Stack, Text } from "../index";
import { ICONS } from "../../icons";
import { memo, useEffect } from "react";
import { StyledOpenBankingInprogressStep } from "./OpenBankingInProgressStep.elements";
import { useDispatch, useSelector } from "react-redux";
import { openBankingActions, openBankingSelectors } from "../../redux";
import useInterval from "use-interval";
import { useTranslation } from "react-i18next";

export interface OpenBankingInProgressStepProps {}

export function OpenBankingInProgressStep(props: OpenBankingInProgressStepProps) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const openBankingData = useSelector(openBankingSelectors.getOpenBankingState);

	useEffect(() => {
		if(openBankingData.accepted === true){
			dispatch(openBankingActions.goToSuccessStep());
		}
		if(openBankingData.accepted === false){
			dispatch(openBankingActions.goToFailedStep());
		}
	}, [openBankingData.accepted]);

	useInterval(() => {
		dispatch(openBankingActions.getQRResponse(openBankingData.operationUid))
	}, (openBankingData.operationUid && openBankingData.accepted == null) ? 2000 : null);

	return (
		<StyledOpenBankingInprogressStep>
			<Card elevation={3} padding={{ x: 10, y: 5 }}>
				<Stack vertical center spacing={7}>
					<Text.Headline.h4 bold color="primary600">
						{t("openBanking.inProgressScreen.title")}
					</Text.Headline.h4>
					<Stack vertical center spacing={7}>
						<img src={ICONS.Success} alt="Success"/>
						<Stack vertical center spacing={3}>
							<Text.Headline.h4 bold color="primary600">
								{t("openBanking.inProgressScreen.description")}
							</Text.Headline.h4>
							<Stack vertical center spacing={8}>
								<Text.Paragraph.p center>{t("openBanking.inProgressScreen.note")}</Text.Paragraph.p>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</Card>
		</StyledOpenBankingInprogressStep>
	);
}

export default memo(OpenBankingInProgressStep);
