import { memo, ReactNode } from "react";
import { LanguageSelector } from "../LanguageSelector/LanguageSelector";
import {
	StyledLoggedOutLayoutContent,
	StyledLoggedOutLayoutFooter,
	StyledLoggedOutLayoutLogo,
	StyledLoggedOutLayoutNav,
	StyledLoggedOutLayoutWrapper,
} from "./LoggedOutLayout.elements";
import { ICONS } from "../../icons";

export interface LoggedOutLayoutProps {
	children?: ReactNode;
}

export function LoggedOutLayout({ children }: LoggedOutLayoutProps) {
	return (
		<StyledLoggedOutLayoutWrapper>
			<StyledLoggedOutLayoutNav>
				<div style={{opacity: "0"}}></div>
				<StyledLoggedOutLayoutLogo src={ICONS.BenkerDarkLogo} alt="Benker Logo" />
				<LanguageSelector />
			</StyledLoggedOutLayoutNav>
			<StyledLoggedOutLayoutContent>{children}</StyledLoggedOutLayoutContent>
			<StyledLoggedOutLayoutFooter></StyledLoggedOutLayoutFooter>
		</StyledLoggedOutLayoutWrapper>
	);
}

export default memo(LoggedOutLayout);
