import styled from "styled-components";

export const StyledLoggedOutLayoutWrapper = styled.div`
	width: 100%;
	min-height: 100vh;
	background-color: ${({ theme }) => theme.colors.netural100};
	padding: 4.8rem 7.2rem 4rem;
	margin: 0;
	position: relative;
`;

export const StyledLoggedOutLayoutContent = styled.main`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const StyledLoggedOutLayoutLogo = styled.img`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

export const StyledLoggedOutLayoutNav = styled.header`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8rem;
	position: relative;
`;

export const StyledLoggedOutLayoutFooter = styled.footer``;
