import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

	@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400&family=Inter:wght@300;400;500;600;700;800&display=swap');

	*, *::before, *::after {
		padding: 0;
		margin: 0;
		box-sizing: border-box;
	}

	body, html {
		height: 100%;
		font-size: 10px;
		font-family: 'Inter' !important;
	}

	#root {
		width: 100%;
		height: 100%;
	}

    a {
        text-decoration: none;
        font-weight: 600;
    }
`;

export default GlobalStyle;
