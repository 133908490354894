import { recoveryActions, recoverySelectors } from "../../redux";
import { ButtonGhost, Card, GooglePlay, Spinner, Stack, Text } from "../index";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StyledContainer, StyledRecoveryFlowAppDownloadQRRead } from "./RecoveryFlowAppDownloadQRRead.elements";
import useInterval from 'use-interval'

/* eslint-disable-next-line */
export interface RecoveryFlowAppDownloadQRReadProps {}

export function RecoveryFlowAppDownloadQRRead(props: RecoveryFlowAppDownloadQRReadProps) {
	const [showQRNumber, setShowQRNumber] = useState(false);
	const { t } = useTranslation();

	const handleShowNumber = () => {
		setShowQRNumber(true);
	};

	const dispatch = useDispatch();

	const token = useSelector(recoverySelectors.getToken);

	useEffect(() => {
		dispatch(recoveryActions.getQR(token))
	}, []);

	const recoveryData = useSelector(recoverySelectors.getRecoveryState);

	useInterval(() => {
		dispatch(recoveryActions.getQRResponse(recoveryData.operationUid))
	}, (recoveryData.operationUid && recoveryData.qrStatus !== "SUCCESS") ? 2000 : null);

	const qr = document.getElementById("qr");
	if (qr) qr.innerHTML = recoveryData.svg;
	if (qr) qr.style.transform = "scale(0.6)";

	useEffect(() => {
		if(recoveryData.qrStatus === "SUCCESS"){
			dispatch(recoveryActions.nextStep())
		}
	}, [recoveryData.qrStatus]);

	return (
		<StyledRecoveryFlowAppDownloadQRRead>
			<Card elevation={3} padding={{ x: 8, y: 5 }}>
				<Stack vertical center spacing={5}>
					<Stack vertical center spacing={3}>
						<Text.Headline.h4 center bold color="primary600">
							{t("recovery.title")}
						</Text.Headline.h4>
					</Stack>
					<Stack vertical center spacing={2}>
						<StyledContainer>
							<Stack vertical center spacing={7} block>
								<Stack vertical center spacing={7} block>
									<Stack vertical center spacing={3} block style={{ padding: "0 1.6rem" }}>
										<Text.Paragraph.p color="netural600" center>
											{t("recovery.downloadApp.firstStep.description")}
										</Text.Paragraph.p>
										<Text.Paragraph.lp bold color="netural900">
											{t("recovery.downloadApp.firstStep.openStore")}
										</Text.Paragraph.lp>
										<Stack center spacing={3}>
											<GooglePlay />										
										</Stack>
									</Stack>
									<Stack vertical center spacing={0} style={{ padding: "0 3.2rem" }}>
										<Text.Paragraph.p color="netural600" center>
											{t("recovery.downloadApp.secondStep.description")}
										</Text.Paragraph.p>
										<Stack center vertical spacing={3}>
											{recoveryData.getQRStatus === "pending" && <div style={{marginTop: "3rem"}}><Spinner/></div>}
											{recoveryData.getQRStatus === "error" && (
												<div style={{marginTop: "3rem"}}>
													<Text.Paragraph.p color="danger300">
														{t("recovery.downloadApp.error")}
													</Text.Paragraph.p>
												</div>
											)}
											{recoveryData.getQRStatus === "success" && <div id="qr"></div>}
											{!showQRNumber && recoveryData.getQRStatus === "success" && <ButtonGhost onClick={handleShowNumber}>{t("recovery.downloadApp.QRReadFailed")}</ButtonGhost>}
											{showQRNumber && (
												<Stack vertical center spacing={1}>
													<Text.Paragraph.p>{t("recovery.downloadApp.qr")}</Text.Paragraph.p>
													<Text.Headline.h3 center bold>
														{recoveryData.shortToken}
													</Text.Headline.h3>
												</Stack>
											)}
										</Stack>
									</Stack>
								</Stack>
							</Stack>
						</StyledContainer>
					</Stack>
				</Stack>
			</Card>
		</StyledRecoveryFlowAppDownloadQRRead>
	);
}

export default memo(RecoveryFlowAppDownloadQRRead);
