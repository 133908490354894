import styled from "styled-components";

export const StyledRecoveryFlowEmailSentStep = styled.div`
    width: 63rem;
`;

export const StyledEmailResent = styled.div`
    background-color: ${({ theme }) => theme.colors.success100};
    color: ${({ theme }) => theme.colors.success500};
    padding: 1rem 1.5rem;
    border-radius: 0.8rem;
`

export const StyledEmailResentError = styled.div`
    background-color: ${({ theme }) => theme.colors.danger100};
    color: ${({ theme }) => theme.colors.success500};
    padding: 1rem 1.5rem;
    border-radius: 0.8rem;
`