import styled, {css} from "styled-components";

export interface IStyledLanguageSelectorProps {
	ref?: any;
}

const WrapperMixin = css`
	background-color: ${({theme}) => theme.colors.white};
	color: ${({theme})=> theme.colors.netural800};
	width: 100%;
	max-width: 14rem;
	border: 2px solid ${({theme})=> theme.colors.netural300};
	border-radius: 0.5em;
	padding: 1em;
`;

export const StyledLanguageSelector = styled.div<IStyledLanguageSelectorProps>`
	font-size: 1.6rem;
	line-height: 2.4rem;
	font-weight: 400;
	max-width: 19.6rem;
	position: relative;
	cursor: pointer;
	${WrapperMixin}
`;

export const StyledLanguageSelectorBody = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export interface IStyledLanguageSelectorDropdownProps {
	isOpen: boolean
}

export const StyledLanguageSelectorDropdown = styled.div<IStyledLanguageSelectorDropdownProps>`
	position: absolute;
	top: calc(100% + 1em);
	left: 0;
	right: 0;
	display: ${({isOpen})=> isOpen ? 'flex' : 'none'};
	flex-direction: column;
	gap: 1em;
	${WrapperMixin}
`;

export const StyledLanguageSelectorItem = styled.div`
	display: flex;
	align-items: center;
	gap: 0.44em;
`;

export const StyledLanguageSelectorDropdownItem = styled.div`
	cursor: pointer;
`;
