import styled, { css, CSSProperties } from "styled-components";
import { ColorType } from "../../themes/default";

export type TextParagraphProps = {
	bold?: boolean;
	caption?: boolean;
	underline?: boolean;
	center?: boolean;
	color?: ColorType;
    style?: CSSProperties | undefined;
};

const TextParagraphMixin = css`
	font-family: "Inter";
`;

export const p = styled.p<TextParagraphProps>`
	${TextParagraphMixin};
	font-size: 1.6rem;
	line-height: 1.5em;
	font-weight: ${({ bold }) => (bold ? 600 : 400)};
	text-transform: ${({ caption }) => (caption ? "uppercase" : "unset")};
	text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
	text-align: ${({ center }) => (center ? "center" : "left")};
	color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.netural800)};

	/* @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
		font-size: 1.8rem;
		line-height: 2.8rem;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.s}px) {
		font-size: 1.4rem;
		line-height: 2.2rem;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
		font-size: 1.2rem;
		line-height: 2rem;
	} */
`;

export const lp = styled.p<TextParagraphProps>`
	${TextParagraphMixin};
	font-size: 1.8rem;
	line-height: 2.8rem;
	font-weight: ${({ bold }) => (bold ? 600 : 400)};
	text-transform: ${({ caption }) => (caption ? "uppercase" : "unset")};
	text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
	text-align: ${({ center }) => (center ? "center" : "left")};
	color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.netural800)};
`;

export const sp = styled.p<TextParagraphProps>`
	${TextParagraphMixin};
	font-size: 1.4rem;
	line-height: 2.2rem;
	font-weight: ${({ bold }) => (bold ? 600 : 400)};
	text-transform: ${({ caption }) => (caption ? "uppercase" : "unset")};
	text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
	text-align: ${({ center }) => (center ? "center" : "left")};
	color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.netural800)};
`;

export const xp = styled.p<TextParagraphProps>`
	${TextParagraphMixin};
	font-weight: ${({ bold }) => (bold ? 600 : 400)};
	text-transform: ${({ caption }) => (caption ? "uppercase" : "unset")};
	text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
	text-align: ${({ center }) => (center ? "center" : "left")};
	font-size: 1.2rem;
	line-height: 2rem;
	color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.netural800)};
`;

export const note = styled.p<TextParagraphProps>`
	${TextParagraphMixin};
	font-weight: ${({ bold }) => (bold ? 600 : 400)};
	font-size: 1rem;
	line-height: 1.4em;
	letter-spacing: 0.1px;
	text-transform: ${({ caption }) => (caption ? "uppercase" : "unset")};
	text-align: ${({ center }) => (center ? "center" : "left")};
	color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.netural800)};
`;

export const underline = styled.p<TextParagraphProps>`
	${TextParagraphMixin};
	color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.netural800)};
`;
