import styled, { css, CSSProperties } from "styled-components";
import { ButtonSize, ButtonType } from "./Button";
export interface IStyledButton {
	buttonType: ButtonType;
	size: ButtonSize;
	leftIcon?: boolean;
	rightIcon?: boolean;
	block?: boolean;
	isLoading?: boolean;
	disabled?: boolean;
	style?: CSSProperties | undefined;
}

export const IStyledButton = styled.button<IStyledButton>`
	border-radius: 0.8rem;
	font-size: 1.4rem;
	padding: 1em 2em;
	cursor: pointer;
	user-select: none;
	width: ${({ block }) => (block ? "100%" : "fit-content")};
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.86em;
	border: none;
	img {
		height: 1.71em;
		width: 1.71em;
	}

	${({ leftIcon, block }) => {
		if (!leftIcon) {
			if (block) {
				return css`
					.leftIcon {
						opacity: 0;
					}
				`;
			} else {
				return css`
					.leftIcon {
						display: none;
					}
				`;
			}
		}
	}}

	${({ rightIcon, block }) => {
		if (!rightIcon) {
			if (block) {
				return css`
					.rightIcon {
						opacity: 0;
					}
				`;
			} else {
				return css`
					.rightIcon {
						display: none;
					}
				`;
			}
		}
	}}

    ${({ block }) => {
		if (block) {
			return css`
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				img {
					margin: auto;
				}
			`;
		}
	}};

	${({ size, leftIcon, rightIcon }) => {
		if (size === ButtonSize.LARGE) {
			return css`
				font-size: 1.4rem;
				line-height: 1.57em;
				padding: ${leftIcon || rightIcon ? "1.07em" : "1.14em"} 2.29em;
				padding-left: ${leftIcon ? "2em" : "2.29em"};
				padding-right: ${rightIcon ? "2em" : "2.29em"};
			`;
		}
		if (size === ButtonSize.MEDIUM) {
			return css`
				font-size: 1.4rem;
				line-height: 1.57em;
				padding: 1.08em 1.71em;
			`;
		}
		if (size === ButtonSize.SMALL) {
			return css`
				font-size: 1.2rem;
				line-height: 2rem;
				padding: 0.6em 1.6em;
				font-weight: bold;
			`;
		}
	}};

	${({ buttonType }) => {
		if (buttonType === ButtonType.PRIMARY) {
			return css`
				background-color: ${({ theme }) => theme.colors.primary300};
				color: ${({ theme }) => theme.colors.white};
				${({ theme }) => theme.mixins.shadows.primaryCTA};
				border: 0.2rem solid transparent;

				&:hover {
					background-color: ${({ theme }) => theme.colors.primary200};
					box-shadow: none;
				}

				&:active {
					background-color: ${({ theme }) => theme.colors.primary400};
					box-shadow: none;
				}

				&:focus {
					background-color: ${({ theme }) => theme.colors.primary300};
					outline: 0.2rem solid #aaaaef;
					outline-offset: 0.2rem;
					box-shadow: none;
				}

				&:disabled {
					color: ${({ theme }) => theme.colors.netural400};
					background-color: ${({ theme }) => theme.colors.netural200};
					cursor: not-allowed;
					box-shadow: none;
				}
			`;
		}
		if (buttonType === ButtonType.PRIMARY_GHOST) {
			return css`
				background-color: transparent;
				color: ${({ theme }) => theme.colors.primary300};
				border: 0.2rem solid ${({ theme }) => theme.colors.primary100};
				font-weight: 600;

				&:hover {
					background-color: ${({ theme }) => theme.colors.primary50};
					border: 0.2rem solid ${({ theme }) => theme.colors.primary50};
				}

				&:active {
					color: ${({ theme }) => theme.colors.primary500};
					background-color: ${({ theme }) => theme.colors.primary100};
					border: 0.2rem solid ${({ theme }) => theme.colors.primary100};
				}

				&:focus {
					background-color: ${({ theme }) => theme.colors.white};
					color: ${({ theme }) => theme.colors.primary300};
					border: 0.2rem solid ${({ theme }) => theme.colors.primary100};
					outline: 0.2rem solid #aaaaef;
					outline-offset: 0.2rem;
				}

				&:disabled {
					color: ${({ theme }) => theme.colors.netural300};
					background-color: ${({ theme }) => theme.colors.white};
					border: 0.2rem solid ${({ theme }) => theme.colors.netural300};
					cursor: not-allowed;
				}
			`;
		}
		if (buttonType === ButtonType.DANGER) {
			return css`
				background-color: ${({ theme }) => theme.colors.danger300};
				color: ${({ theme }) => theme.colors.white};
				font-weight: 600;

				&:hover {
					background-color: ${({ theme }) => theme.colors.danger200};
				}

				&:active {
					background-color: ${({ theme }) => theme.colors.danger400};
				}

				&:focus {
					background-color: ${({ theme }) => theme.colors.danger300};
					outline: 0.2rem solid #ffa7a0;
					outline-offset: 0.2rem;
				}

				&:disabled {
					color: ${({ theme }) => theme.colors.danger200};
					background-color: ${({ theme }) => theme.colors.danger100};
					cursor: not-allowed;
				}
			`;
		}
		if (buttonType === ButtonType.DANGER_GHOST) {
			return css`
				background-color: transparent;
				color: ${({ theme }) => theme.colors.danger300};
				border: 0.2rem solid ${({ theme }) => theme.colors.danger100};
				font-weight: 600;

				&:hover {
					background-color: ${({ theme }) => theme.colors.danger100};
				}

				&:active {
					color: ${({ theme }) => theme.colors.danger500};
					background-color: ${({ theme }) => theme.colors.danger200};
					border: 0.2rem solid ${({ theme }) => theme.colors.danger200};
				}

				&:focus {
					background-color: ${({ theme }) => theme.colors.white};
					color: ${({ theme }) => theme.colors.danger300};
					border: 0.2rem solid ${({ theme }) => theme.colors.danger100};
					outline: 0.2rem solid #ffa7a0;
					outline-offset: 0.2rem;
				}

				&:disabled {
					color: ${({ theme }) => theme.colors.danger200};
					background-color: ${({ theme }) => theme.colors.white};
					border: 0.2rem solid ${({ theme }) => theme.colors.danger200};
					cursor: not-allowed;
				}
			`;
		}
	}};
`;
