import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// Importing translation files
import translation_EN from "./langs/en.json";
import translation_HU from "./langs/hu.json";
import translation_LT from "./langs/lt.json"

//Creating object with the variables of imported translation files

const resources = {
	en: {
		translation: translation_EN,
	},
	hu: {
		translation: translation_HU,
	},
	lt: {
		translation: translation_LT,
	},
};

const options = {
	// order and from where user language should be detected
	order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  
	// keys or params to lookup language from
	lookupQuerystring: 'lng',
	lookupCookie: 'i18next',
	lookupLocalStorage: 'i18nextLng',
	lookupSessionStorage: 'i18nextLng',
	lookupFromPathIndex: 0,
	lookupFromSubdomainIndex: 0,
  
	// cache user language on
	caches: ['localStorage', 'cookie'],
	//excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  
	// optional expire and domain for set cookie
	//cookieMinutes: 10,
	//cookieDomain: 'myDomain',
  
	// optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
	//cookieOptions: { path: '/', sameSite: 'strict' }
};

//i18N Initialization

i18n.use(initReactI18next).use(LanguageDetector).init({
	resources,
	keySeparator: ".",
	fallbackLng: 'en',
  	supportedLngs: ['hu', 'en', 'lt'],
	interpolation: {
		escapeValue: false,
	},
	detection: options,
});

export default i18n;
