import { memo } from "react";
import styled from "styled-components";
import { ICONS } from "../../icons";

/* eslint-disable-next-line */
export interface GooglePlayProps {}

const StyledGooglePlay = styled.a`
	outline: none;
	text-decoration: none;
	cursor: pointer;
`;

const StyledGooglePlayImg = styled.img`
	width: 16rem;
	height: 4.8rem;
`;

export function GooglePlay(props: GooglePlayProps) {
	return (
		<StyledGooglePlay>
			<StyledGooglePlayImg alt="Google Play" src={ICONS.GooglePlaySVG} />
		</StyledGooglePlay>
	);
}

export default memo(GooglePlay);
