import styled, { css } from "styled-components";

const RowMixin = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
	user-select: none;
`;

export const StyledLabel = styled.label``;

export const StyledLabelRow = styled.div`
	${RowMixin};
`;

export const StyledBottomRow = styled.div`
	${RowMixin};
`;

type StyledInputProps = {
	hasError?: boolean;
	ghost?: boolean;
};

export const StyledInput = styled.input<StyledInputProps>`
	font-size: 1.6rem;
	line-height: 1.5em;
	caret-color: ${({ theme, hasError }) => (hasError ? theme.colors.netural600 : theme.colors.primary200)};
	color: ${({ theme }) => theme.colors.netural800};
	border: none;
	outline: none;
	flex: 1;
	height: 100%;
	&::placeholder {
		color: ${({ theme }) => theme.colors.netural400};
	}

	&:focus-within {
		//font-size: 1.4rem;
		//font-weight: 600;
	}
`;

type StyledInputWrapperProps = {
	hasError?: boolean;
	isFocused?: boolean;
	ghost?: boolean;
};

export const StyledInputWrapper = styled.div<StyledInputWrapperProps>`
	display: flex;
	align-items: center;
	border-radius: ${({ theme }) => theme.radius.basic}rem;
	border: 2px solid
		${({ theme, hasError, isFocused }) => {
			if (isFocused) {
				return theme.colors.primary300;
			}
			if (hasError) {
				return theme.colors.danger300;
			}
			return theme.colors.netural300;
		}};
	padding: 1.6rem;
	cursor: text;
	background-color: ${({ theme, ghost }) => (ghost ? "transparent" : "white")};

	${StyledInput}:focus {
		border-color: ${({ theme }) => theme.colors.primary300};
	}
`;

export const StyledEyeIcon = styled.img`
	width: 2.4rem;
	user-select: none;
	cursor: pointer;
`;
