import React, { memo, useEffect } from "react";
import {
	OpenBankingQRStep,
    OpenBankingInProgressStep,
    OpenBankingSuccessStep,
    OpenBankingFailedStep,
    LoggedOutLayout
} from "../components/index";
import { OpenBankingSteps, openBankingSelectors, openBankingActions } from "../redux/openBanking";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const OpenBankingScreen = () => {
	const STEPS = {
		[OpenBankingSteps.OPENBANKING_START]: OpenBankingQRStep,
        [OpenBankingSteps.OPENBANKING_INPROGRESS]: OpenBankingInProgressStep,
		[OpenBankingSteps.OPENBANKING_SUCCESS]: OpenBankingSuccessStep,
        [OpenBankingSteps.OPENBANKING_FAILED]: OpenBankingFailedStep,
	};

	const currentStep: OpenBankingSteps = useSelector(openBankingSelectors.getOpenBankingStep);
	const CurrentStepComponent = STEPS[currentStep];

	const params = useQuery();
	const dispatch = useDispatch();
	const history = useHistory();


	useEffect(() => {
		const clientId = params.get("client_id");
		const redirectUri = params.get("redirect_uri");
		const state = params.get("state");
		const responseType = params.get("response_type");
		const scope = params.get("scope");

		if (clientId) {
			const consent = scope!.trim().split(/\s+/);
		
			const data = {
				clientId,
				redirectUri,
				state,
				responseType,
				consent
			}

			dispatch(openBankingActions.startOpenBankingFlow(data));
			history.push("/openBanking");
		}
	}, [dispatch, params]);

    if(currentStep === OpenBankingSteps.OPENBANKING_START){
        return <CurrentStepComponent />;
    }
	return (
		<LoggedOutLayout>
			<CurrentStepComponent />;
		</LoggedOutLayout>
	);
};

export default memo(OpenBankingScreen);
