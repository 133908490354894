import { memo, ReactNode } from "react";
import { CSSProperties } from "styled-components";
import { IStyledButton } from "./Button.elements";

export enum ButtonType {
	PRIMARY = "PRIMARY",
	PRIMARY_GHOST = "PRIMARY_GHOST",
	DANGER = "DANGER",
	DANGER_GHOST = "DANGER_GHOST",
}

export enum ButtonSize {
	LARGE = "LARGE",
	MEDIUM = "MEDIUM",
	SMALL = "SMALL",
}
export interface ButtonProps {
	type: ButtonType;
	size?: ButtonSize;
	leftIcon?: string;
	rightIcon?: string;
	block?: boolean;
	disabled?: boolean;
	isLoading?: boolean;
	onClick?: () => void;
	children?: ReactNode;
	style?: CSSProperties | undefined;
	form?: string;
	isSubmit?: boolean;
}

const Button = (props: ButtonProps) => {
	const {
		type,
		size = ButtonSize.MEDIUM,
		leftIcon,
		rightIcon,
		block = false,
		disabled = false,
		isLoading = false,
		onClick = () => {},
		children,
		style,
		form = "",
		isSubmit = false,
	} = props;

	return (
		// eslint-disable-next-line @typescript-eslint/no-redeclare
		<IStyledButton
			style={style}
			buttonType={type}
			size={size}
			leftIcon={!!leftIcon}
			rightIcon={!!rightIcon}
			block={block}
			disabled={disabled}
			isLoading={isLoading}
			onClick={onClick}
			form={form}
			type={isSubmit ? "submit" : "button"}
		>
			<img alt="icon" src={leftIcon} className="leftIcon" />
			{children}
			<img alt="icon" src={rightIcon} className="rightIcon" />
		</IStyledButton>
	);
};

export default memo(Button);

export const ButtonPrimary = (props: Omit<ButtonProps, "type">) => (
	<Button {...props} type={ButtonType.PRIMARY}>
		{props.children}
	</Button>
);

export const ButtonGhost = (props: Omit<ButtonProps, "type">) => (
	<Button {...props} type={ButtonType.PRIMARY_GHOST}>
		{props.children}
	</Button>
);
