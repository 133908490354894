import { recoveryActions, recoverySelectors } from "../../redux";
import { Card, Stack, Text, TextInput, TextInputType, ButtonPrimary, Spinner } from "../index";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { memo, useEffect, useId, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyledRecoveryFlowAddEmailStep } from "./RecoveryFlowAddEmailStep.elements";
import { useDispatch, useSelector } from "react-redux";
import { StyledEmailResentError } from "../RecoveryFlowEmailSentStep/RecoveryFlowEmailSentStep.elements";
import { ICONS } from "../../icons";

export interface RecoveryFlowAddEmailStepProps {}

interface FormValues {
	email: string;
}

export function RecoveryFlowAddEmailStep(props: RecoveryFlowAddEmailStepProps) {
	const [status, setStatus] = useState("");
	const { t } = useTranslation();

	const email = useSelector(recoverySelectors.getEmail);

	const dispatch = useDispatch();

	const recoveryData = useSelector(recoverySelectors.getRecoveryState);

	useEffect(() => {
		setStatus(recoveryData.emailStatus);
		if(recoveryData.emailStatus === "error"){
			setTimeout(() => {
				setStatus("");
			}, 3000)
		}
		if(recoveryData.emailStatus === "success"){
			if(recoveryData.reviewerType !== "") {
				dispatch(recoveryActions.startReviewFlow())
			} else {
				dispatch(recoveryActions.nextStep());
			}
		}
	}, [recoveryData.emailStatus]);

	const formId = useId();

	const schema = Joi.object({
		email: Joi.string().email({ tlds: { allow: false } }),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<FormValues>({
		resolver: joiResolver(schema),
	});

	useEffect(() => {
		if (!!email) {
			reset({ email });
		}
	}, [email]);

	const onSubmit = (data: FormValues) => {
		dispatch(recoveryActions.setEmail(data.email))
		dispatch(recoveryActions.sendRecoveryRequest(data.email));
	};

	return (
		<StyledRecoveryFlowAddEmailStep>
			<Card elevation={3} padding={{ x: 10, y: 5 }}>
				<Stack vertical center spacing={5}>
					<Text.Headline.h4 center bold color="primary600">
						{t("recovery.title")}
					</Text.Headline.h4>
					<Stack vertical center spacing={5}>
						<Text.Paragraph.p center style={{ maxWidth: "43rem" }}>
							{t("recovery.addEmail.description")}
						</Text.Paragraph.p>
						<Stack block vertical spacing={10}>
							<form id={formId} onSubmit={handleSubmit(onSubmit)} style={{ width: "36rem", margin: "auto" }}>
								<TextInput
									error={errors.email?.message}
									register={register}
									label={t("recovery.emailAddress")}
									type={TextInputType.EMAIL}
									placeholder={t("recovery.emailAddress")}
									name="email"
								></TextInput>
							</form>
							<Stack block vertical center spacing={3}>
								{(status !== "pending" && status !== "error") && (
									<ButtonPrimary isSubmit form={formId} style={{ minWidth: "16rem" }}>
										{t("recovery.addEmail.sendRequest")}
									</ButtonPrimary>
								)}
								{status === "error" && (
									<StyledEmailResentError>
										<Stack center spacing={1}>
											<img src={ICONS.Cancel} alt="Error"></img>
											<Text.Paragraph.sp>{t("recovery.addEmail.sendError")}</Text.Paragraph.sp>
										</Stack>
									</StyledEmailResentError>
								)}
								{status === "pending" && <Spinner/>}
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</Card>
		</StyledRecoveryFlowAddEmailStep>
	);
}

export default memo(RecoveryFlowAddEmailStep);
