import styled, { css } from "styled-components";
import { ColorType } from "../../themes/default";

export type TextHeadlineProps = {
	bold?: boolean;
	center?: boolean;
	color?: ColorType;
};

const HeadlineMixin = css`
	font-family: "Archivo";
`;

export const h1 = styled.h1<TextHeadlineProps>`
	font-weight: ${({ bold }) => (bold ? 700 : 400)};
	font-size: 4rem;
	line-height: 1.35em;
	${HeadlineMixin};
	text-align: ${({ center }) => (center ? "center" : "left")};
	color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.netural800)};

	@media (max-width: ${({ theme }) => theme.breakpoints.s}px) {
		font-weight: ${({ bold }) => (bold ? 600 : 400)};
		font-size: 3.2rem;
		line-height: 4.4rem;
	}
`;

export const h2 = styled.h2<TextHeadlineProps>`
	${HeadlineMixin};
	font-weight: ${({ bold }) => (bold ? 700 : 400)};
	font-size: 3.2rem;
	line-height: 1.375em;
	text-align: ${({ center }) => (center ? "center" : "left")};
	color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.netural800)};

	@media (max-width: ${({ theme }) => theme.breakpoints.s}px) {
		font-weight: ${({ bold }) => (bold ? 600 : 400)};
		font-size: 2.4rem;
		line-height: 3.4rem;
	}
`;

export const h3 = styled.h3<TextHeadlineProps>`
	font-weight: ${({ bold }) => (bold ? 700 : 400)};
	font-size: 2.6rem;
	line-height: 4rem;
	${HeadlineMixin};
	text-align: ${({ center }) => (center ? "center" : "left")};
	color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.netural800)};

	@media (max-width: ${({ theme }) => theme.breakpoints.s}px) {
		font-weight: ${({ bold }) => (bold ? 600 : 400)};
		font-size: 2.2rem;
		line-height: 3.2rem;
	}
`;

export const h4 = styled.h4<TextHeadlineProps>`
	font-weight: ${({ bold }) => (bold ? 700 : 400)};
	font-size: 2.2rem;
	line-height: 3.4rem;
	${HeadlineMixin};
	text-align: ${({ center }) => (center ? "center" : "left")};
	color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.netural800)};

	@media (max-width: ${({ theme }) => theme.breakpoints.s}px) {
		font-weight: ${({ bold }) => (bold ? 600 : 400)};
		font-size: 2.2rem;
		line-height: 3.2rem;
	}
`;
