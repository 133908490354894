import { RecoveryFlow } from "./recovery.constants";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { recoveryActions } from "./recovery.actions";

export const RECOVERY_FEATURE_KEY = "recovery";

export interface RecoveryState {
	currentStepIndex: number;
	email: string;
	token: string;
	flow: RecoveryFlow | null;
	svg: string;
	shortToken: number;
	operationUid: string;
	qrStatus: string;
	getQRStatus: string;
	emailStatus: string;
	resendEmailStatus: string;
	sendPasswordStatus: string;
	reviewerType: string;
}

export const initialRecoveryState: RecoveryState = {
	currentStepIndex: 0,
	email: "",
	token: "",
	flow: RecoveryFlow.RECOVERY,
	svg: "",
	shortToken: 0,
	operationUid: "",
	qrStatus: "",
	getQRStatus: "",
	emailStatus: "",
	resendEmailStatus: "",
	sendPasswordStatus: "",
	reviewerType: "",
};

export const recoverySlice = createSlice({
	name: RECOVERY_FEATURE_KEY,
	initialState: initialRecoveryState,
	reducers: {
		nextStep: (state: RecoveryState) => {
			state.currentStepIndex += 1;
		},
		previousStep: (state: RecoveryState) => {
			state.currentStepIndex -= 1;
		},
		startEmailFlow: (state: RecoveryState, action: PayloadAction<string>) => {
			state.currentStepIndex = 0;
			state.token = action.payload;
			state.flow = RecoveryFlow.EMAIL_RECOVERY;
		},
		startReviewFlow: (state: RecoveryState) => {
			state.currentStepIndex = 0;
			state.flow = RecoveryFlow.RECOVERY_REVIEW;
		},
		reset: (state: RecoveryState) => {
			state.currentStepIndex = 0;
			state.emailStatus = "";
			state.resendEmailStatus = "";
		},
		setEmail: (state: RecoveryState, action: PayloadAction<string>) => {
			state.email = action.payload;
		},
		setRecoveryData: (state: RecoveryState, action: PayloadAction<any>) => {
			state.token = action.payload.token;
			state.shortToken = action.payload.shortToken;
			state.svg = action.payload.svg;
			state.operationUid = action.payload.operationUid;
		},
		setQrStatus: (state: RecoveryState, action: PayloadAction<string>) => {
			state.qrStatus = action.payload;
		},
		setToken:  (state: RecoveryState, action: PayloadAction<string>) => {
			state.token = action.payload;
		},
		setReviewerType: (state: RecoveryState, action: PayloadAction<string>) => {
			state.reviewerType = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(recoveryActions.sendRecoveryRequest.fulfilled, (state: RecoveryState) => {
			state.emailStatus = "success";
		});
		builder.addCase(recoveryActions.sendRecoveryRequest.pending, (state: RecoveryState) => {
			state.emailStatus = "pending";
		});
		builder.addCase(recoveryActions.sendRecoveryRequest.rejected, (state: RecoveryState) => {
			state.emailStatus = "error";
		});
		builder.addCase(recoveryActions.resendRecoveryRequest.fulfilled, (state: RecoveryState) => {
			state.resendEmailStatus = "success";
		});
		builder.addCase(recoveryActions.resendRecoveryRequest.pending, (state: RecoveryState) => {
			state.resendEmailStatus = "pending";
		});
		builder.addCase(recoveryActions.resendRecoveryRequest.rejected, (state: RecoveryState) => {
			state.resendEmailStatus = "error";
		});
		builder.addCase(recoveryActions.getQR.pending, (state: RecoveryState) => {
			state.getQRStatus = "pending";
		});
		builder.addCase(recoveryActions.getQR.fulfilled, (state: RecoveryState) => {
			state.getQRStatus = "success";
		});
		builder.addCase(recoveryActions.getQR.rejected, (state: RecoveryState) => {
			state.getQRStatus = "error";
		});
		builder.addCase(recoveryActions.sendGooglePassword.pending, (state: RecoveryState) => {
			state.sendPasswordStatus = "pending";
		});
		builder.addCase(recoveryActions.sendGooglePassword.fulfilled, (state: RecoveryState) => {
			state.sendPasswordStatus = "success";
		});
		builder.addCase(recoveryActions.sendGooglePassword.rejected, (state: RecoveryState) => {
			state.sendPasswordStatus = "error";
		});
	},
});

export const recoveryReducer = recoverySlice.reducer;
