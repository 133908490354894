import React, { memo, useEffect } from "react";
import {
	RecoveryFlowAddEmailStep,
	RecoveryFlowEmailSentStep,
	RecoveryFlowAppDownloadQRRead,
	RecoveryFlowAppDownloadQRReadSuccess,
	LoggedOutLayout,
	RecoveryReviewPasswordStep,
} from "../components/index";
import { RecoverySteps, recoverySelectors, recoveryActions } from "../redux/recovery";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const RecoveryScreen = () => {
	const STEPS = {
		[RecoverySteps.RECOVERY_ADD_EMAIL]: RecoveryFlowAddEmailStep,
		[RecoverySteps.RECOVERY_EMAIL_SENT]: RecoveryFlowEmailSentStep,
		[RecoverySteps.RECOVERY_DOWNLOAD_APP]: RecoveryFlowAppDownloadQRRead,
		[RecoverySteps.RECOVERY_SUCCESS]: RecoveryFlowAppDownloadQRReadSuccess,
		[RecoverySteps.RECOVERY_REVIEW_PW]: RecoveryReviewPasswordStep,
	};

	const currentStep: RecoverySteps = useSelector(recoverySelectors.getRecoveryStep);
	const CurrentStepComponent = STEPS[currentStep];

	const params = useQuery();
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		const token = params.get("token");
		if (token) {
			dispatch(recoveryActions.startEmailFlow(token));
			history.push("/recovery");
		};
	}, [dispatch, params]);


	return (
		<LoggedOutLayout>
			<CurrentStepComponent />
		</LoggedOutLayout>
	);
};

export default memo(RecoveryScreen);
