import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppLanguage } from "./ui.constants";

export const UI_FEATURE_KEY = "ui";

export interface UiState {
	language: AppLanguage;
}

export const initialUiState: UiState = {
	language: AppLanguage.EN,
};

export const uiSlice = createSlice({
	name: UI_FEATURE_KEY,
	initialState: initialUiState,
	reducers: {
		setApplicationLanguage: (state: UiState, action: PayloadAction<AppLanguage>) => {
			state.language = action.payload;
		},
	},
	// extraReducers: (builder) => {},
});

export const uiReducer = uiSlice.reducer;
