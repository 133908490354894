import { CSSProperties } from "styled-components";
import { StyledCard } from "./Card.elements";
import { memo, ReactNode } from "react";
import { ColorType } from "../../themes/default";

/* eslint-disable-next-line */

export type PaddingValueType = number;

export type ExplicitPaddingType = {
	t: PaddingValueType;
	r: PaddingValueType;
	b: PaddingValueType;
	l: PaddingValueType;
};
export type AxisPaddingType = {
	x: PaddingValueType;
	y: PaddingValueType;
};

export type PaddingType = PaddingValueType | ExplicitPaddingType | AxisPaddingType;

export interface CardProps {
	padding: PaddingType;
	ghost?: boolean;
	flat?: boolean;
	elevation: number;
	block?: boolean;
	children?: ReactNode;
	style?: CSSProperties | undefined;
	borderColor?: ColorType;
	color?: ColorType;
}

export function Card({
	padding,
	children,
	ghost = false,
	flat = false,
	block = false,
	elevation = 0,
	style,
	borderColor,
	color,
}: CardProps) {
	return (
		<StyledCard
			elevation={elevation}
			padding={padding}
			ghost={ghost}
			flat={flat}
			block={block}
			style={style}
			borderColor={borderColor}
			color={color}
		>
			{children}
		</StyledCard>
	);
}

export default memo(Card);
