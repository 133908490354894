import { OpenBankingFlow } from './openBanking.constants';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { openBankingActions } from './openBanking.actions';

export const OPENBANKING_FEATURE_KEY = "openBanking";

export interface OpenBankingState {
	currentStepIndex: number;
	flow: OpenBankingFlow | null;
	clientId: string;
	redirectUri: string;
	state: string;
	responseType: string;
	scope: [];
	svg: string,
	operationUid: string,
	status: string,
	qrStatus: string,
	accepted: boolean | null,
	callbackUrl: string,
}

export const initialOpenBankingState: OpenBankingState = {
	currentStepIndex: 0,
	flow: OpenBankingFlow.OPENBANKING,
	clientId: "",
	redirectUri: "",
	state: "",
	responseType: "",
	scope: [],
	svg: "",
	operationUid: "",
	status: "",
	qrStatus: "",
	accepted: null,
	callbackUrl: "",
};

export const openBankingSlice = createSlice({
	name: OPENBANKING_FEATURE_KEY,
	initialState: initialOpenBankingState,
	reducers: {
		nextStep: (state: OpenBankingState) => {
			state.currentStepIndex += 1;
		},
		previousStep: (state: OpenBankingState) => {
			state.currentStepIndex -= 1;
		},
		reset: (state: OpenBankingState) => {
			state.currentStepIndex = -1;
		},
		goToFailedStep: (state: OpenBankingState) => {
			state.currentStepIndex = 3;
		},
		goToSuccessStep: (state: OpenBankingState) => {
			state.currentStepIndex = 2;
		},
		startOpenBankingFlow: (state: OpenBankingState, action: PayloadAction<any>) => {
			state.clientId = action.payload.clientId;
			state.redirectUri = action.payload.redirectUri;
			state.state = action.payload.state;
			state.responseType = action.payload.responseType;
			state.scope = action.payload.consent;
		},
		saveQrData: (state: OpenBankingState, action: PayloadAction<any>) => {
			state.svg = action.payload.qr;
			state.operationUid = action.payload.operationUid;
		},
		setStatus: (state: OpenBankingState, action: PayloadAction<any>) => {
			state.qrStatus = action.payload.state;
		},
		setAccepted: (state: OpenBankingState, action: PayloadAction<any>) => {
			state.accepted = action.payload.result.accepted;
		},
		setCallbackUrl: (state: OpenBankingState, action: PayloadAction<any>) => {
			state.callbackUrl = action.payload.result.callbackUrl;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(openBankingActions.authorize.fulfilled, (state: OpenBankingState) => {
			state.status = "success";
		});
		builder.addCase(openBankingActions.authorize.pending, (state: OpenBankingState) => {
			state.status = "pending";
		});
		builder.addCase(openBankingActions.authorize.rejected, (state: OpenBankingState) => {
			state.status = "error";
		});
	},
});

export const openBankingReducer = openBankingSlice.reducer;