export enum OpenBankingFlow {
	OPENBANKING = "OpenBankingFlow",
}

export enum OpenBankingSteps {
    OPENBANKING_START,
    OPENBANKING_INPROGRESS,
    OPENBANKING_SUCCESS,
    OPENBANKING_FAILED,
}

export const OPENBANKING_STEPS: OpenBankingSteps[] = [OpenBankingSteps.OPENBANKING_START, OpenBankingSteps.OPENBANKING_INPROGRESS, OpenBankingSteps.OPENBANKING_SUCCESS, OpenBankingSteps.OPENBANKING_FAILED];

export const OPENBANKING_FLOW: { [key in OpenBankingFlow]: OpenBankingSteps[] } = {
	[OpenBankingFlow.OPENBANKING]: OPENBANKING_STEPS,
};
