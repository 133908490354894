import React, {useEffect} from "react";

export function useOnOutsideClick(
	ref: React.MutableRefObject<HTMLElement | undefined>,
	handler: () => void
) {
	useEffect(()=> {
		const listener = (event: any) => {
			// Do nothing if clicking ref's element or descendent elements
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}
			handler();
		};

		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);

		return () => {
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	},[ref, handler]);
}

export default useOnOutsideClick;
