import { openBankingSlice } from "./openBanking.slice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


const reducerActions = openBankingSlice.actions;

export enum OpenBankingAction {
	AUTHORIZE = "openBanking/authorize",
	GET_QR_RESPONSE = "openBanking/getQrResponse",
}

export const authorize = createAsyncThunk(
	OpenBankingAction.AUTHORIZE, 
	async (params: any, thunkAPI) => {
		const response = await axios.post("/api/psd2/authorize", {
			clientId: params.clientId, 
			redirectUri: params.redirectUri, 
			state: params.state, 
			responseType: params.responseType, 
			scope: params.scope
		}
		).then(res => res.data)
		thunkAPI.dispatch(openBankingActions.saveQrData(response));
	}
);

const getQRResponse = createAsyncThunk(OpenBankingAction.GET_QR_RESPONSE,
	async (operationUid: string, thunkAPI) => {
		const response = await axios.get(`/api/operations/${operationUid}`).then(res => res.data);
		thunkAPI.dispatch(openBankingActions.setStatus(response))
		thunkAPI.dispatch(openBankingActions.setAccepted(response))
		thunkAPI.dispatch(openBankingActions.setCallbackUrl(response))
	}
)

export const openBankingActions = {
	authorize,
	getQRResponse,
	...reducerActions,
};
