import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import store from './redux';
import { Default, GlobalStyle } from './themes';
import * as ReactDOMClient from "react-dom/client";
import "./i18n"


const root = ReactDOMClient.createRoot(document.querySelector("#root") as HTMLElement);

root.render(
		<Provider store={store}>
			<GlobalStyle />
			<ThemeProvider theme={Default.default}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</ThemeProvider>
		</Provider>
);