import { recoveryActions, recoverySelectors } from "../../redux";
import { ButtonGhost, Card, Spinner, Stack, Text } from "../index";
import { memo, useEffect, useState } from "react";
import { StyledEmailResent, StyledEmailResentError, StyledRecoveryFlowEmailSentStep } from "./RecoveryFlowEmailSentStep.elements";
import { ICONS } from "../../icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
export interface RecoveryFlowEmailSentStepProps {
	isEmailResent?: boolean;
}

export function RecoveryFlowEmailSentStep(props: RecoveryFlowEmailSentStepProps) {
	const email = useSelector(recoverySelectors.getEmail);
	const [isEmailResent, setIsEmailResent] = useState(false);
	const [status, setStatus] = useState("");

	const handleResendEmail = () => {
		dispatch(recoveryActions.resendRecoveryRequest(email));
		setIsEmailResent((prev) => !prev);
	};

	const dispatch = useDispatch();

	const { t } = useTranslation();

	const handleGoBack = () => {
		dispatch(recoveryActions.reset());
	};

	const emailStatus = useSelector(recoverySelectors.getResendEmailStatus);

	useEffect(() => {
		setStatus(emailStatus);
		if(emailStatus === ""){
			return
		}
		if(emailStatus !== "pending"){
			setTimeout(() => {
				setStatus("");
				setIsEmailResent((prev) => !prev);
			}, 3000)
		}
	}, [emailStatus]);

	return (
		<StyledRecoveryFlowEmailSentStep>
			<Card elevation={3} padding={{ x: 10, y: 8 }}>
				<Stack vertical center spacing={3}>
					<Text.Headline.h4 center bold color="primary600">
						{t("recovery.title")}
					</Text.Headline.h4>
					<Stack vertical center spacing={5}>
						<Text.Paragraph.p center>{t("recovery.emailSent.description")}</Text.Paragraph.p>
						<Stack vertical center spacing={2}>
							<Text.Paragraph.p center color="netural600">
								{t("recovery.emailSent.sentTo")}
							</Text.Paragraph.p>
							<Stack vertical center spacing={7}>
								<Text.Paragraph.lp bold>{email}</Text.Paragraph.lp>
								<Stack vertical center spacing={5}>
									<ButtonGhost onClick={handleGoBack} style={{ minWidth: "15rem" }}>
										{t("common.button.back")}
									</ButtonGhost>
									{!isEmailResent && (
										<div
											onClick={() => {
												handleResendEmail();
											}}
										>
											<Text.Paragraph.sp bold underline color="primary300" style={{ cursor: "pointer" }}>
												{t("recovery.emailSent.resendEmail")}
											</Text.Paragraph.sp>
										</div>
									)}
									{isEmailResent && (
										<Stack>
											{status === "pending" &&
											<Stack center spacing={1}>
												<Spinner/>
											</Stack>
											}
											<Stack center spacing={1}>
												{status === "success" && (
												<StyledEmailResent>
													<Stack center spacing={1}>
														<img src={ICONS.GreenPipe} alt="Success"></img>
														<Text.Paragraph.sp>{t("recovery.emailSent.emailResent")}</Text.Paragraph.sp>
													</Stack>
												</StyledEmailResent>
												)}
												{status === "error" && ( 
												<StyledEmailResentError>
													<Stack center spacing={1}>
														<img src={ICONS.Cancel} alt="Error"></img>
														<Text.Paragraph.sp>{t("recovery.emailSent.resendError")}</Text.Paragraph.sp>
													</Stack>
												</StyledEmailResentError>
												)}
											</Stack>
									</Stack>
									)}
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</Card>
		</StyledRecoveryFlowEmailSentStep>
	);
}

export default memo(RecoveryFlowEmailSentStep);
