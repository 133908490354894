import { Text, Stack, LanguageSelector, Spinner } from "../index";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ICONS } from "../../icons";
import {
	StyledConsentContainer,
	StyledContainer,
	StyledQRContainer,
    StyledQRStepLayout,
} from "./OpenBankingQRStep.elements";
import { openBankingActions, openBankingSelectors } from "../../redux";
import { useTranslation } from "react-i18next";
import useInterval from "use-interval";

/* eslint-disable-next-line */
export interface OpenBankingQRStepProps {}

export function OpenBankingQRStep(props: OpenBankingQRStepProps) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

    const openBankingData = useSelector(openBankingSelectors.getOpenBankingState);

    useEffect(() => {
        if(openBankingData.clientId) {
	        dispatch(openBankingActions.authorize(openBankingData));
        }
	}, [openBankingData.clientId]);

    const qr = document.getElementById("qr");
	if (qr) qr.innerHTML = openBankingData.svg;
	if (qr) qr.style.transform = "scale(0.8)";

	useInterval(() => {
		dispatch(openBankingActions.getQRResponse(openBankingData.operationUid))
	}, (openBankingData.operationUid) ? 2000 : null);

	const consent = openBankingData.scope;

	useEffect(() => {
		if(openBankingData.qrStatus === "EXECUTING"){
			dispatch(openBankingActions.nextStep())
		}
	}, [openBankingData.qrStatus]);
	  
	return (
		<StyledQRStepLayout>
			<StyledConsentContainer>
				<Stack vertical center spacing={5}>
					<Text.Headline.h2 bold color="white">
						{t("openBanking.qrReadScreen.permissions")}
					</Text.Headline.h2>
					<Stack vertical center spacing={0.5}>
						{consent.map((consent) => <Text.Paragraph.p color="netural300" key={consent}>{t("openBanking.consent." + consent)}</Text.Paragraph.p>)}
					</Stack>
				</Stack>
			</StyledConsentContainer>
			<StyledQRContainer>
                    <Stack center block spaceBetween>
                        <img src={ICONS.BenkerDarkLogo} alt="Benker Logo" />
                        <LanguageSelector />
                    </Stack>
                    <StyledContainer>
                        <Stack vertical center spacing={0}>
                            <Stack vertical center spacing={1}>
                                <Text.Headline.h4 bold center color="primary600">
									{t("openBanking.qrReadScreen.title")}
                                </Text.Headline.h4>
                            </Stack>
							{openBankingData.status === "error" && <div style={{marginTop: "3rem"}}>
													<Text.Paragraph.p color="danger300">
														{t("openBanking.qrReadScreen.error")}
													</Text.Paragraph.p>
												</div>}
							{openBankingData.status === "pending" && <div style={{marginTop: "3rem"}}><Spinner/></div>}
                            <div id="qr"></div>
                        </Stack>                    
                    </StyledContainer>
			</StyledQRContainer>
		</StyledQRStepLayout>
	);
}

export default memo(OpenBankingQRStep);
