
import { configureStore } from "@reduxjs/toolkit";
import { openBankingReducer, OPENBANKING_FEATURE_KEY } from "./openBanking";
import { uiReducer, UI_FEATURE_KEY } from "./ui/ui.slice";
import { recoveryReducer, RECOVERY_FEATURE_KEY } from "./recovery";


const store = configureStore({
	reducer: {
		[UI_FEATURE_KEY]: uiReducer,
		[RECOVERY_FEATURE_KEY]: recoveryReducer,
		[OPENBANKING_FEATURE_KEY]: openBankingReducer,
	},
	// Additional middleware can be passed to this array
	middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
	devTools: process.env.NODE_ENV !== "production",
	// Optional Redux store enhancers
	enhancers: [],
});

export type StoreState = ReturnType<typeof store.getState>;
export type DispatchType = typeof store.dispatch;

export default store;

export * from "./recovery";
export * from "./ui";
export * from "./openBanking";

