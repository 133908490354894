import React, { memo } from "react";
import { StyledDiv, StyledRing, StyledWrapper } from "./Spinner.elements";


export interface SpinnerProps {}

export function Spinner(props: SpinnerProps) {

return (
    <StyledWrapper>
        <StyledRing>
            <StyledDiv></StyledDiv>
            <StyledDiv></StyledDiv>
            <StyledDiv></StyledDiv>
            <StyledDiv></StyledDiv>
        </StyledRing>
    </StyledWrapper>
)
};

export default memo(Spinner);
