import styled from "styled-components";
import { StackProps } from "../index";

export type StyledStackProps = Omit<StackProps, "children">;

export const StyledStack = styled.div<StyledStackProps>`
	display: flex;
	gap: ${({ theme, spacing }) => spacing! * theme.spacing.basic}rem;
	flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
	align-items: ${({ center, end }) => {
		if (center) return "center";
		if (end) return "end";
		return "stretch";
	}};
	width: ${({ block }) => (block ? "100%" : "fit-content")};
	justify-content: ${({ spaceBetween }) => (spaceBetween ? "space-between" : "start")};
`;
