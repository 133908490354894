export enum RecoveryFlow {
	RECOVERY = "RecoveryFlow",
	EMAIL_RECOVERY = "RecoveryAfterEmail",
	RECOVERY_REVIEW = "ReviewRecoveryFlow",
}

export enum RecoverySteps {
	RECOVERY_ADD_EMAIL,
	RECOVERY_EMAIL_SENT,
	RECOVERY_DOWNLOAD_APP,
	RECOVERY_SUCCESS,
	RECOVERY_REVIEW_PW,
}

export const RECOVERY_STEPS: RecoverySteps[] = [RecoverySteps.RECOVERY_ADD_EMAIL, RecoverySteps.RECOVERY_EMAIL_SENT];

export const RECOVERY_AFTER_EMAIL_STEPS: RecoverySteps[] = [RecoverySteps.RECOVERY_DOWNLOAD_APP, RecoverySteps.RECOVERY_SUCCESS];

export const RECOVERY_REVIEW_STEPS: RecoverySteps[] = [RecoverySteps.RECOVERY_REVIEW_PW]

export const RECOVERY_FLOW: { [key in RecoveryFlow]: RecoverySteps[] } = {
	[RecoveryFlow.RECOVERY]: RECOVERY_STEPS,
	[RecoveryFlow.EMAIL_RECOVERY]: RECOVERY_AFTER_EMAIL_STEPS,
	[RecoveryFlow.RECOVERY_REVIEW]: RECOVERY_REVIEW_STEPS,
};
