import { ButtonPrimary, Card, Stack, Text } from "../index";
import { ICONS } from "../../icons";
import { memo } from "react";
import { StyledOpenBankingSuccessStep } from "./OpenBankingSuccessStep.elements";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { openBankingSelectors } from "../../redux";

export interface OpenBankingSuccessStepProps {}

export function OpenBankingSuccessStep(props: OpenBankingSuccessStepProps) {
	const { t } = useTranslation();

	const openBankingData = useSelector(openBankingSelectors.getOpenBankingState);

	return (
		<StyledOpenBankingSuccessStep>
			<Card elevation={3} padding={{ x: 10, y: 5 }}>
				<Stack vertical center spacing={7}>
					<Text.Headline.h4 bold color="primary600">
						{t("openBanking.successScreen.title")}
					</Text.Headline.h4>
					<Stack vertical center spacing={7}>
						<img src={ICONS.Success} alt="Success"/>
						<a href={openBankingData.callbackUrl}>
							<ButtonPrimary style={{minWidth: "20rem"}}>{t("common.button.next")}</ButtonPrimary>
						</a>
					</Stack>
				</Stack>
			</Card>
		</StyledOpenBankingSuccessStep>
	);
}

export default memo(OpenBankingSuccessStep);
