import styled from "styled-components";

export interface StyledTabs {
	isActive?: boolean;
}

export const StyledRecoveryFlowAppDownloadQRRead = styled.div`
	max-width: 63rem;
`;

export const StyledContainer = styled.div`
	max-width: 50rem;
	margin: auto;
	background-color: ${({ theme }) => theme.colors.netural100};
	padding: 4rem 0;
`;

export const StyledTabTitle = styled.div<StyledTabs>`
	width: 25rem;
	height: 5.6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: ${({ isActive }) => (isActive ? "rgba(71, 50, 165, 0.1)" : "netural800")};
	position: relative;
	cursor: pointer;

	::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 4px;
		bottom: 0px;
		left: 0px;
		background-color: ${({ isActive }) => (isActive ? "#5D3FDE" : "#E9EAEB")};
	}
`;
