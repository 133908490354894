import AppleStoreSvg from "./assets/icons/logo/apple-store-logo.svg";
import GooglePlaySvg from "./assets/icons/logo/google-play-logo.svg";
import HuFlag from "./assets/icons/flags/flag-hu.svg";
import EnFlag from "./assets/icons/flags/flag-en.svg";
import LtFlag from "./assets/icons/flags/flag-lt.svg"
import BenkerDarkLogo from "./assets/icons/logo/benker_logo_dark.svg";
import GreenPipe from "./assets/icons/pipe-green.svg";
import BenkerLogo from "./assets/icons/logo/benker-logo-white.svg";
import Success from "./assets/icons/success.svg";
import ArrowUp from "./assets/icons/arrow-up.svg"
import ArrowDown from "./assets/icons/arrow-down.svg"
import Eye from "./assets/icons/eye.svg";
import Load from "./assets/icons/load.svg";
import Cancel from "./assets/icons/cancel.svg";


export const ICONS = {
	AppleStoreSVG: AppleStoreSvg,
	GooglePlaySVG: GooglePlaySvg,
	HUFlag: HuFlag,
	ENFlag: EnFlag,
	LTFlag: LtFlag,
	BenkerDarkLogo: BenkerDarkLogo,
	BenkerLogo: BenkerLogo,
	GreenPipe: GreenPipe,	
	Success: Success,
    ArrowUpSVG: ArrowUp,
    ArrowDownSVG: ArrowDown,
	Eye: Eye,
	Load: Load,
	Cancel: Cancel,
};

export type IconTypes = typeof ICONS;
