import { Card, Stack, Text } from "../index";
import { StyledRecoveryFlowAppDownloadQRReadSuccess } from "./RecoveryFlowAppDownloadQRReadSuccess.elements";
import { ICONS } from "../../icons";
import { memo } from "react";
import { useTranslation } from "react-i18next";

export interface RecoveryFlowAppDownloadQRReadSuccessProps {}

export function RecoveryFlowAppDownloadQRReadSuccess(props: RecoveryFlowAppDownloadQRReadSuccessProps) {
	const { t } = useTranslation();

	return (
		<StyledRecoveryFlowAppDownloadQRReadSuccess>
			<Card elevation={3} padding={{ x: 10, y: 5 }}>
				<Stack vertical center spacing={7}>
					<Text.Headline.h4 bold color="primary600">
						{t("recovery.title")}
					</Text.Headline.h4>
					<Stack vertical center spacing={7}>
						<img src={ICONS.Success} alt="Success"/>
						<Stack vertical center spacing={3}>
							<Text.Headline.h4 bold color="primary600">
								{t("recovery.QRReadSuccess.title")}
							</Text.Headline.h4>
							<Stack vertical center spacing={8}>
								<Text.Paragraph.p center>{t("recovery.QRReadSuccess.description")}</Text.Paragraph.p>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</Card>
		</StyledRecoveryFlowAppDownloadQRReadSuccess>
	);
}

export default memo(RecoveryFlowAppDownloadQRReadSuccess);
