/* eslint-disable-next-line */
import {
	StyledLanguageSelector,
	StyledLanguageSelectorBody,
	StyledLanguageSelectorDropdown,
	StyledLanguageSelectorDropdownItem,
	StyledLanguageSelectorItem,
} from "./LanguageSelector.elements";
import { useOnOutsideClick } from "../../hooks";
import { memo, useEffect, useRef, useState } from "react";

import { ICONS } from "../../icons";

import { uiActions, AppLanguage, uiSelectors } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../i18n/i18n";
import Cookies from "universal-cookie";

export interface LanguageSelectorProps {}

export function LanguageSelector(props: LanguageSelectorProps) {
	const elementRef = useRef<HTMLElement>();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const dispatch = useDispatch();

	const onOutsideClickHandler = () => {
		setIsOpen(false);
	};

	const onLanguageSelectorClickHandler = () => {
		setIsOpen((prevState) => !prevState);
	};

	useOnOutsideClick(elementRef, onOutsideClickHandler);

	interface IUILanguage {
		shortText: string;
		text: string;
		icon: string;
	}

	const languages: { [key in AppLanguage]: IUILanguage } = {
		[AppLanguage.EN]: {
			shortText: "EN",
			text: "English",
			icon: ICONS.ENFlag,
		},
		[AppLanguage.HU]: {
			shortText: "HU",
			text: "Magyar",
			icon: ICONS.HUFlag,
		},
		[AppLanguage.LT]: {
			shortText: "LT",
			text: "Lietuvių",
			icon: ICONS.LTFlag,
		},
	};

	const initialLanguage = useSelector(uiSelectors.getLanguage);

	const [selectedLanguage, setSelectedLanguage] = useState<IUILanguage>(languages[initialLanguage]);

	const onLanguageItemClickHandler = (language: IUILanguage) => {
		setSelectedLanguage(language);
		switch (language.shortText) {
			case "EN":
				dispatch(uiActions.setApplicationLanguage(AppLanguage.EN));
				i18n.changeLanguage("en");
				break;
			case "HU":
				dispatch(uiActions.setApplicationLanguage(AppLanguage.HU));
				i18n.changeLanguage("hu");
				break;
			case "LT":
				dispatch(uiActions.setApplicationLanguage(AppLanguage.LT));
				i18n.changeLanguage("lt");
				break;
		}
	};

	const cookies = new Cookies();

	useEffect(() => {
		const language = cookies.get("i18next");
		switch (language) {
			case "en":
				setSelectedLanguage(languages.en);
				break;
			case "hu":
				setSelectedLanguage(languages.hu);
				break;
			case "lt":
				setSelectedLanguage(languages.lt);
				break;
			default:
				setSelectedLanguage(languages.en);
		}
	},[])

	return (
		<StyledLanguageSelector ref={elementRef} onClick={onLanguageSelectorClickHandler}>
			<StyledLanguageSelectorBody>
				<StyledLanguageSelectorItem>
					<img alt={`flag-icon-${selectedLanguage.shortText}`} src={selectedLanguage.icon} />
					{selectedLanguage.shortText}
				</StyledLanguageSelectorItem>
				<img alt="language-selector-arrow" src={isOpen ? ICONS.ArrowUpSVG : ICONS.ArrowDownSVG} />
			</StyledLanguageSelectorBody>

			<StyledLanguageSelectorDropdown isOpen={isOpen}>
				{Object.values(languages).map((language) => (
					<StyledLanguageSelectorDropdownItem key={`language-${language.shortText}`} onClick={() => onLanguageItemClickHandler(language)}>
						<StyledLanguageSelectorItem>
							<img alt={`flag-icon-${selectedLanguage.shortText}`} src={language.icon} />
							{language.text}
						</StyledLanguageSelectorItem>
					</StyledLanguageSelectorDropdownItem>
				))}
			</StyledLanguageSelectorDropdown>
		</StyledLanguageSelector>
	);
}

export default memo(LanguageSelector);
