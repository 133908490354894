import styled from "styled-components";
import { CardProps, AxisPaddingType } from "../index";

type StyledCardProps = Omit<CardProps, "children">;

const getElevation = (theme: any, elevation: number, zero: boolean) => {
	if (zero) return null;
	return theme.mixins.shadows[`elevation${elevation}`];
};

export const StyledCard = styled.div<StyledCardProps>`
	border-radius: ${({ theme }) => theme.radius.basic}rem;
	background-color: ${({ theme, flat, ghost, color }) =>
		ghost ? "transparent" : flat ? theme.colors.netural200 : color ? theme.colors[color] : theme.colors.white};
	width: ${({ block }) => (block ? "100%" : "fit-content")};
	${({ theme, elevation, ghost, flat }) => getElevation(theme, elevation, !!ghost || !!flat)}
	padding: ${({ theme, padding }) => {
		switch (typeof padding) {
			case "number":
				return `${theme.spacing.basic * padding}rem`;
			case "object":
				if ((padding as AxisPaddingType)?.x && (padding as AxisPaddingType)?.y)
					return `${theme.spacing.basic * (padding as AxisPaddingType).y}rem ${theme.spacing.basic * (padding as AxisPaddingType).x}rem`;
		}
	}};
	border: ${({ theme, borderColor }) => (borderColor ? "0.1rem solid" : "none")}
		${({ theme, borderColor }) => (borderColor ? theme.colors[borderColor] : "")};
`;
