import { OpenBankingSteps, OPENBANKING_FLOW } from "./openBanking.constants";
import { OpenBankingState, OPENBANKING_FEATURE_KEY } from "./openBanking.slice";
import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../index";

const getOpenBankingState = (rootState: StoreState): OpenBankingState => rootState[OPENBANKING_FEATURE_KEY];

const getOpenBankingStep = createSelector(getOpenBankingState, (state: OpenBankingState) => {
	const flow: OpenBankingSteps[] = OPENBANKING_FLOW[state.flow!];
	return flow[state.currentStepIndex];
});

export const openBankingSelectors = {
	getOpenBankingState,
	getOpenBankingStep,
};