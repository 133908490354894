import { UiState, UI_FEATURE_KEY } from "./ui.slice";
import { StoreState } from "../index";
import { createSelector } from "@reduxjs/toolkit";

const getUiState = (rootState: StoreState): UiState => rootState[UI_FEATURE_KEY];

const getLanguage = createSelector(getUiState, (state: UiState) => state.language);


export const uiSelectors = {
	getUiState,
	getLanguage
};
