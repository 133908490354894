import styled from "styled-components";

export const StyledQRStepLayout = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	margin: auto;
`;

export const StyledQR = styled.img`
	width: 16rem;
	height: 16rem;
	background-color: ${({ theme }) => theme.colors.primary300};
`;

export const StyledConsentContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.primary600};
	display: flex;
	justify-content: center;
    align-items: center;
    width: 30%;
    padding: 1rem;
`;

export const StyledQRContainer = styled.div`
	width: 70%;
    padding: 5rem;
`;

export const StyledContainer = styled.div`
    display: flex;
    align-items: center;
	justify-content: center;
    height: 90%;
	max-width: 50rem;
	margin: auto;
`